<template>
  <theme-provider :color="{ middle: { 'bg-base': '#fff' }, primary: { DEFAULT: '#1896ff' } }">
    <a-modal :visible="_visible" :footer="null" width="340px" :closable="false">
      <a-card title="请输入谷歌验证码" class="text-black">
        <div class="verification-container ">
          <input
            v-for="(code, index) in verificationCodes"
            :key="index"
            :id="'input-' + index"
            v-model="verificationCodes[index]"
            maxlength="1"
            class="verification-input"
            @input="handleInput(index, $event)"
            @keydown="handleKeyDown(index, $event)"
          />
        </div>
      </a-card>
    </a-modal>
  </theme-provider>
</template>

<script setup>
  import { nextTick, onMounted, ref } from 'vue';
  import { ThemeProvider } from 'stepin';
  import { notification } from 'ant-design-vue';
  import { useAccountStore } from '@/store';

  const accountStore = useAccountStore();

  const props = defineProps({
    modelValue: {
      type: String,
      default: '',
    },
    verify: {
      type: Boolean,
      default: true,
    },
  });
  const _visible = ref(false);
  const emits = defineEmits(['success', 'update:modelValue']);
  const verificationCodes = ref(['', '', '', '', '', '']);

  const handleInput = (index, event) => {
    const value = event.target.value;
    verificationCodes.value[index] = value;
    emits('update:modelValue', verificationCodes.value.join(''));

    // 判断是否输入完成
    if (verificationCodes.value.join('').length === 6) {
      if (props.verify) {
        verifyTotp();
      } else {
        emits('success');
      }
      return;
    }
    // 自动跳到下一个输入框
    if (value && index < verificationCodes.value.length - 1) {
      const nextInput = event.target.nextElementSibling;
      if (nextInput) {
        nextTick(() => {
          nextInput.focus();
        });
      }
    }
  };

  const handleKeyDown = (index, event) => {
    // 处理删除操作
    if (event.key === 'Backspace' && !event.target.value && index > 0) {
      const prevInput = event.target.previousElementSibling;
      if (prevInput) {
        nextTick(() => {
          prevInput.focus();
        });
      }
    }
  };

  const verifyTotp = () => {
    console.log('2FA verify');
    accountStore.googleAuth(accountStore.account.username, verificationCodes.value.join(''))
      .then(() => _visible.value = false)
      .catch(err => {
        console.log(err);
        notification.error({ message: err.response.data.description });
      })
      .finally(() => reset());
  };

  const show = () => {
    _visible.value = true;
  };

  const reset = () => {
    verificationCodes.value = ['', '', '', '', '', ''];
    let firstInput = document.getElementById('input-0');
    if (firstInput) {
      firstInput.focus();
    }
  };

  defineExpose({ show });

  onMounted(() => reset());
</script>

<style lang="less" scoped>
  .verification-container {
    display: flex;
    justify-content: center;
  }

  .verification-input {
    width: 34px;
    height: 34px;
    margin-right: 10px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ebebeb;
    border-radius: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  .verification-input:focus {
    outline: none;
  }
</style>
